import React, { useEffect, useState, useContext } from "react";
import { keys } from "lodash";
import AuthUserContext from "../../components/AuthUserContext";
import AccountContext from "../../contexts/AccountContext";

import Royalties from "./Royalties";
import isAdmin from "../../utils/isAdmin";

const RoyaltiesWrapper = ({ query }) => {
  const [_query, setQuery] = useState("");
  const account = useContext(AccountContext);
  const user = useContext(AuthUserContext);

  useEffect(() => {
    if (account) {
      const isMember = keys(account.members).includes(user.uid);
      const ownerEmail = account.owner_email;

      if (isAdmin()) {
        setQuery(query);
      } else if (isMember) {
        setQuery(ownerEmail);
      } else {
        setQuery(user.email);
      }
    }
  }, [account, query, user.uid]);

  return <Royalties query={_query} />;
};

export default RoyaltiesWrapper;
