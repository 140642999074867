import { firebase } from "../firebase";
import { get } from "lodash";

export default () => {
  const uid = get(firebase, "auth.currentUser.uid", false);

  if (!uid) {
    return false;
  }

  const agustin = "GVNSllWiNyepmhkLNw3rX8xOJpP2"; // agustin@ojiva.es
  const gerardoTornero = "AwGBrzdfvIPGzEANHv1Mc7ZHZVN2"; // gerardo.tornero@therecordingconsort.com
  const production = "7ij6Ktb00WX1WWoSCgcfw03rEvA3"; // production@therecordingconsort.com
  const distribution = "RiBkqzzsodTYMNl9E0PoD7lde4p2"; // distribution@therecordingconsort.com
  const agucho = "7nOTHZvg36f97Whx3HfUE3IXfQ52"; // agucho@gmail.com

  const x = `${agustin}${gerardoTornero}${production}${distribution}${agucho}`;
  return x.indexOf(uid) > -1;
};
