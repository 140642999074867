import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { Link } from "react-router-dom";
import * as routes from "../../../constants/routes";
import AlbumStatusChip from "./AlbumStatusChip";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import dateFormat from "dateformat";
import AlbumRestrictions from "./AlbumRestrictions";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  title: {
    textDecoration: "none",
    fontWeight: 900,
    color: "#444",
  },
  author: {
    textDecoration: "none",
    fontWeight: 400,
    color: "#444",
  },
  card: {
    marginBottom: theme.spacing(1),
    width: "100%",
    height: "100%",
    backgroundColor: "rgb(204, 170, 102)",
    position: "relative",
  },
  noCoverTitle: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    textAlign: "center",
    color: "white",
    padding: "1em",
  },
  media: {
    width: "100%",
    paddingTop: "90%",
  },
  overlay: {
    width: "100%",
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 1,
    display: "flex",
    gap: theme.spacing(1),
    flexWrap: "wrap",
  },
  status: {
    fontSize: ".7rem",
    fontWeight: 700,
    color: "white",
  },
  restrictions: {
    fontSize: ".6rem",
    fontWeight: 700,
    color: "white",
  },
}));

const albumFormat = (format) => {
  const fmt = format.trim().toLowerCase();

  switch (fmt) {
    case "album":
    case "full length":
      return "Album";
    case "ep":
      return "EP";
    case "single":
      return "Single";
  }
};

const Album = ({ album }) => {
  const {
    artists = {},
    title = "",
    titleLabel = "",
    upc = "",
    photos = {},
    release,
    format,
    version,
  } = album;

  const [showTitle, setShowTitle] = useState("");

  useEffect(() => {
    setShowTitle(titleLabel || title);
  }, [title, titleLabel]);

  const classes = useStyles();

  if (!upc || !showTitle) return null;

  let primaryArtist = null;

  for (let k in artists) {
    if (artists[k].role === "Artista principal" && artists[k].country !== "")
      primaryArtist = artists[k].name;
  }

  if (!album) return null;

  return (
    <Link
      to={routes.ALBUM_VIEW.replace(":upc", upc)}
      style={{
        textDecoration: "none",
        pointerEvents:
          album.status === "received" || album.status === "updating"
            ? "none"
            : "auto",
      }}
    >
      <div className={classes.container}>
        <Card className={classes.card}>
          {!photos.cover && (
            <Typography className={classes.noCoverTitle} variant="h5">
              {showTitle}
            </Typography>
          )}

          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={photos.cover ? photos.cover.url : null}
              title={showTitle}
            />
          </CardActionArea>

          <div className={classes.overlay}>
            <AlbumStatusChip
              statusKey={album.status}
              className={classes.status}
            />

            <AlbumRestrictions
              className={classes.restrictions}
              contentId={album.contentId}
            />
          </div>
        </Card>

        <Typography className={classes.title} variant="h6">
          {showTitle}
        </Typography>
        <Typography className={classes.author}>
          {dateFormat(release, "yyyy")} <b>·</b> {albumFormat(format)}
          <b> · </b>
          {primaryArtist?.replaceAll("|", " / ")}
        </Typography>
      </div>
    </Link>
  );
};

export default Album;
