import React, { useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import { Tooltip } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import useConfig from "../../../hooks/useConfig";

const AlbumRestrictions = ({ contentId, className }) => {
  if (contentId !== "eligible") return null;

  const config = useConfig();
  const liveStatus = config.statuses.live;

  return (
    <Tooltip
      title={`Album aprobado para monetizar en redes sociales y YouTube`}
    >
      <Chip
        label={<CheckIcon />}
        style={{
          backgroundColor: liveStatus.color,
          fontSize: ".8em",
        }}
        className={className}
        color="primary"
        size="small"
      />
    </Tooltip>
  );
};

export default AlbumRestrictions;
